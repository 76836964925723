import { useCallback, useState } from "react";
import classNames from "classnames";
import type { Location } from "history";
import { defer } from "lodash";
import { isMobileSafari } from "react-device-detect";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { HOME_PAGE_POST_REQUEST_KEY } from "@circle-react/components/HomePageV2/usePostList";
import {
  CurrentPostContextProvider,
  CurrentSpaceContextProvider,
} from "@circle-react/contexts";
import { PostsDetailsContextProvider } from "@circle-react/contexts/postsDetailsContext";
import type { Post } from "@circle-react/types";
import { Modal } from "@circle-react-shared/uikit/ModalV2";
import { useShowPost } from "../Show/useShowPost";
import { ModalBody } from "./ModalBody";
import { ModalHeader } from "./ModalHeader";
import { Navigation } from "./Navigation";

interface LocationState {
  prevLocation?: Location<LocationState>;
  postList?: Post[];
  currentPage?: number;
  params: Record<string, unknown>;
  isWithinProfileContext?: boolean;
  profileContextMember?: {
    id: number;
    public_uid: string;
  };
}

export const PostModalComponent = () => {
  const [isNavigatingInsidePostModal, setIsNavigatingInsidePostModal] =
    useState(false);
  const history = useHistory();
  const location = useLocation<LocationState | undefined>();
  const {
    isLoading: isOpeningPostModal,
    postResource,
    perPagePostIds,
    space,
  } = useShowPost();
  const { state } = location;
  const {
    prevLocation,
    postList: initialPostList,
    currentPage: initialPage = 1,
    params,
    isWithinProfileContext,
    profileContextMember,
  } = state ?? {};

  const wasInFeed = prevLocation?.pathname === "/feed";
  const shouldDisplayLoader = isOpeningPostModal || isNavigatingInsidePostModal;
  const queryClient = useQueryClient();
  const postListQueryKey = wasInFeed
    ? [HOME_PAGE_POST_REQUEST_KEY]
    : ["posts", space?.id];

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries(postListQueryKey);
  }, [queryClient, postListQueryKey]);

  const deferredInvalidateQueries = useCallback(() => {
    defer(invalidateQueries);
  }, [invalidateQueries]);

  return (
    <CurrentSpaceContextProvider spaceId={space?.id}>
      <PostsDetailsContextProvider perPagePostIds={perPagePostIds}>
        <CurrentPostContextProvider
          post={postResource.data}
          onPostChange={postResource.onPostChange}
          usedIn="minimal-post-modal"
        >
          <Modal
            isOpen
            onClose={() => {
              if (prevLocation) {
                history.push(prevLocation);
                deferredInvalidateQueries();
              }
            }}
            contentPortalId="post-modal-portal"
          >
            <Navigation
              initialPostList={initialPostList}
              initialPage={initialPage}
              currentPost={postResource?.data}
              prevLocation={prevLocation}
              params={params}
              isNavigatingInsidePostModal={isNavigatingInsidePostModal}
              setIsNavigatingInsidePostModal={setIsNavigatingInsidePostModal}
              wasInFeed={wasInFeed}
              isLoading={shouldDisplayLoader}
              isWithinProfileContext={isWithinProfileContext}
              profileContextMember={profileContextMember}
            />
            <Modal.Overlay />
            <Modal.Content
              size="xl"
              className={classNames("sm:!my-[5vh]", {
                "sm:!max-h-[85vh]": isMobileSafari,
                "sm:!max-h-[90vh]": !isMobileSafari,
              })}
            >
              <ModalHeader
                spaceId={space.id}
                onChange={postResource?.onPostChange}
                isLoading={shouldDisplayLoader}
                invalidateQueries={deferredInvalidateQueries}
              />
              <ModalBody
                isLoading={shouldDisplayLoader}
                isRenderedOutsideTheSpace={wasInFeed}
                post={postResource.data}
                onPostDestroy={() => {
                  if (prevLocation) {
                    history.push(prevLocation);
                  }
                }}
              />
            </Modal.Content>
          </Modal>
        </CurrentPostContextProvider>
      </PostsDetailsContextProvider>
    </CurrentSpaceContextProvider>
  );
};
