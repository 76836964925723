import { t } from "i18n-js";
import { useLocation } from "react-router-dom";
import {
  memberSettingsPaths,
  planSettingsPath,
  settingsNavPath,
  spacesSettingsPath,
} from "@/react/helpers/urlHelpers";
import { useApiUsage } from "@circle-react/components/SettingsApp/DevelopersOverview/useApiUsage";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { separator } from "./separator";
import type { DropdownButton, DropdownLink, DropdownOption } from "./types";
import { useAdminIcons } from "./useAdminIcons";

interface AdminOptionsProps {
  memberCount?: number;
  showCustomizeTheme: boolean;
  moderation: DropdownLink;
  toggleTheme: DropdownButton[];
  viewAs: DropdownButton;
  post: DropdownLink[];
  isCommunityOnPlus: boolean;
  isWorkflowsEnabled: boolean;
  isSpacesCrudEnabled: boolean;
  isAffiliatesEnabled: boolean;
  isBannerVisible: boolean;
  isMarketingHubStatusEnabled: boolean;
  shortcutsModalButton: DropdownButton | null;
}

export const useAdminOptions = ({
  memberCount,
  showCustomizeTheme,
  moderation,
  toggleTheme,
  viewAs,
  post,
  isCommunityOnPlus,
  isWorkflowsEnabled,
  isSpacesCrudEnabled,
  isAffiliatesEnabled,
  isBannerVisible,
  isMarketingHubStatusEnabled,
  shortcutsModalButton,
}: AdminOptionsProps): DropdownOption[] => {
  const {
    membersIcon,
    marketingHubIcon,
    spacesCrudIcon,
    liveStreamIcon,
    workflowsIcon,
    paywallsIcon,
    plansIcon,
    analyticsIcon,
    affiliatesIcon,
    settingsIcon,
    customizeThemeIcon,
    inviteMemberIcon,
  } = useAdminIcons();
  const { isApiAvailable } = useApiUsage();
  const { iconSize, isV3Enabled } = useIsV3();
  const { pathname } = useLocation();
  const options: DropdownOption[] = [];

  const membersOption: DropdownOption = {
    value: "members",
    label: t("section_audience"),
    pathTo: memberSettingsPaths.index(),
    icon: membersIcon,
    iconSize,
  };

  if (memberCount) {
    membersOption.badge = String(memberCount);
  }

  options.push(membersOption);

  if (isMarketingHubStatusEnabled) {
    options.push({
      value: t("sidebar_email"),
      label: t("sidebar_email"),
      pathTo: settingsNavPath.email.broadcast,
      icon: marketingHubIcon,
      iconSize,
    });
  }

  options.push(...post);

  if (isSpacesCrudEnabled) {
    options.push({
      value: t("settings.spaces.title"),
      label: t("settings.spaces.title"),
      pathTo: spacesSettingsPath(),
      icon: spacesCrudIcon,
      iconSize,
    });
  }

  options.push(moderation);

  options.push({
    value: t("sidebar_live_streams"),
    label: t("sidebar_live_streams"),
    pathTo: "/settings/live_streams",
    icon: liveStreamIcon,
    iconSize,
  });

  if (isWorkflowsEnabled) {
    options.push({
      value: t("sidebar_workflows"),
      label: t("sidebar_workflows"),
      pathTo: "/settings/workflows",
      icon: workflowsIcon,
      iconSize,
    });
  }

  options.push({
    value: t("sidebar_paywalls"),
    label: t("sidebar_paywalls"),
    pathTo: "/settings/paywalls",
    icon: paywallsIcon,
    iconSize,
  });

  options.push({
    value: t("pricing_plans.title"),
    label: isCommunityOnPlus
      ? t("settings.billing.plans.plus")
      : t("settings.billing.plans.title"),
    pathTo: planSettingsPath(),
    icon: plansIcon,
    iconSize,
  });

  options.push({
    value: t("analytics.analytics"),
    label: t("analytics.analytics"),
    pathTo: "/settings/analytics",
    icon: analyticsIcon,
    iconSize,
  });

  if (isAffiliatesEnabled) {
    options.push({
      value: t("sidebar_paywalls_affiliates"),
      label: t("sidebar_paywalls_affiliates"),
      pathTo: "/settings/affiliates_settings",
      icon: affiliatesIcon,
      iconSize,
    });
  }

  if (isV3Enabled) {
    options.push({
      label: t("settings_nav_v3.site.label"),
      value: t("settings_nav_v3.site.label"),
      icon: "20-display",
      pathTo: settingsNavPathV3.site.index,
      iconSize,
    });
  }

  if (isApiAvailable) {
    options.push({
      label: t("settings_nav_v3.developers.title"),
      value: t("settings_nav_v3.developers.title"),
      icon: "20-stack-dev",
      pathTo: settingsNavPathV3.developers.overview,
      iconSize,
    });
  }

  options.push({
    value: t("sidebar_settings"),
    label: t("sidebar_settings"),
    pathTo: "/settings",
    icon: settingsIcon,
    iconSize,
  });

  options.push(separator);

  options.push(...toggleTheme);

  if (showCustomizeTheme) {
    options.push({
      value: t("customize_theme.title"),
      label: t("customize_theme.title"),
      pathTo: "/settings/theme",
      icon: customizeThemeIcon,
      iconSize,
    });
  }

  if (shortcutsModalButton) {
    options.push(shortcutsModalButton);
  }

  options.push(separator);

  if (!isBannerVisible) {
    options.push(viewAs);
  }

  options.push({
    value: t("sidebar_invite_member"),
    label: t("sidebar_invite_member"),
    pathTo: `${settingsNavPath.members.invite}?from=${pathname}`,
    icon: inviteMemberIcon,
    iconSize,
  });

  return options;
};
