import { t } from "i18n-js";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import { TRIGGER_RESOURCE_TYPE } from "@circle-react/components/SettingsApp/Workflows/constants";
import { useSupportUnreleasedWorkflowsFeaturesEnabled } from "@circle-react/components/shared/Workflows/hooks/useSupportUnreleasedWorkflowsFeaturesEnabled";
import { usePunditUserContext } from "@circle-react/contexts";
import { useContactsInWorkflowsEnabled } from "@circle-react/hooks/useContactsInWorkflowsEnabled";
import { availableEvents } from "../Events";
import { memberTagged, memberUntagged } from "./MemberTag";

const contactTagEventTranslations = {
  [memberTagged.value]: {
    labelKey: "settings.workflows.form.events.contact_tagged.title_new",
    descriptionKey:
      "settings.workflows.form.events.contact_tagged.description_new",
  },
  [memberUntagged.value]: {
    labelKey: "settings.workflows.form.events.contact_untagged.title_new",
    descriptionKey:
      "settings.workflows.form.events.contact_untagged.description_new",
  },
};

export const useReleasedEvents = () => {
  const supportUnreleasedWorkflowsFeaturesEnabled =
    useSupportUnreleasedWorkflowsFeaturesEnabled();
  const { currentCommunitySettings } = usePunditUserContext();
  const { data: gamificationSettings } = useGamificationSettings();
  const isGamificationEnabled = currentCommunitySettings?.gamification_enabled;
  const shouldShowGamificationLeveledUpEvent =
    gamificationSettings?.enabled && isGamificationEnabled;

  const isContactsInWorkflowsEnabled = useContactsInWorkflowsEnabled();

  let allAvailableEvents = availableEvents;
  if (!shouldShowGamificationLeveledUpEvent) {
    allAvailableEvents = allAvailableEvents.filter(
      availableEvent =>
        availableEvent.resource !== TRIGGER_RESOURCE_TYPE.GAMIFICATION_LEVEL,
    );
  }

  if (isContactsInWorkflowsEnabled) {
    allAvailableEvents = allAvailableEvents.map(availableEvent => {
      const translation = contactTagEventTranslations[availableEvent.value];
      if (translation) {
        return {
          ...availableEvent,
          label: t(translation.labelKey),
          description: t(translation.descriptionKey),
        };
      }
      return availableEvent;
    });
  }

  const releasedEvents = supportUnreleasedWorkflowsFeaturesEnabled
    ? allAvailableEvents
    : allAvailableEvents.filter(
        availableEvent => availableEvent.released !== false,
      );

  return { releasedEvents };
};
