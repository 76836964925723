import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";

interface DateTimeProps {
  date: string;
  shouldIncludeTime: boolean;
}

export const Datetime = ({ date, shouldIncludeTime }: DateTimeProps) => {
  const dateFormat = shouldIncludeTime
    ? dateFormats.short_date_at_short_time
    : dateFormats.long_date;

  return (
    <span className="break-normal">
      {date
        ? formattedDateTime({
            dateTime: new Date(date),
            format: dateFormat,
          })
        : "-"}
    </span>
  );
};
