import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { noop } from "lodash";
import type { UsePaywallCheckoutReturn } from "./usePaywallCheckout";
import { initialPaymentError, usePaywallCheckout } from "./usePaywallCheckout";

interface PaywallCheckoutContext extends UsePaywallCheckoutReturn {
  hasAlreadyPurchased: boolean;
  checkoutConfirmationUrl: string;
  children: ReactNode;
  communityRootUrl: string;
  areCouponsEnabled: boolean;
  creditCard: any;
  currentCommunity: any;
  currentCommunityMember: any;
  currentCommunityMemberPaymentProcessorSessionClientSecret?: string;
  currentUser: any;
  isMemberAddressRequired: boolean;
  memberBillingInfo: any;
  loginUrl: string;
  paywall: any;
  shouldShowTaxIdForm: boolean;
  isTaxIdMandatory: boolean;
  isBrazilianAccountAndProcessorStripeBr: boolean;
  isPaywallDigitalWalletEnabled: boolean;
  isMemberWithSavedPaymentMethods: boolean;
  isMemberUsingSavedPaymentMethod: boolean;
  setIsMemberUsingSavedPaymentMethod: (value: boolean) => void;
}

const initialState: PaywallCheckoutContext = {
  hasAlreadyPurchased: false,
  applyCouponCode: noop,
  checkoutConfirmationUrl: "",
  children: null,
  clearCouponCode: noop,
  communityRootUrl: "",
  areCouponsEnabled: false,
  creditCard: {},
  currentCommunity: {},
  currentCommunityMember: {},
  currentUser: {},
  enableTaxIdCollection: noop,
  getSelectedPrice: () => ({ trial_enabled: false }),
  isMemberAddressRequired: false,
  memberBillingInfo: {},
  isTaxIdCollectionEnabled: false,
  loginUrl: "",
  paymentError: initialPaymentError,
  paywall: {},
  previewMutation: {},
  setMemberAddressAttributes: noop,
  setPaymentError: noop,
  setSelectedPrice: noop,
  isCardInfoComplete: false,
  setIsCardInfoComplete: noop,
  isCardInfoValid: false,
  isPaywallActive: false,
  isCardInfoRequired: false,
  isSetupIntentRequired: false,
  arePostCreateActionsRequired: false,
  handleError: noop,
  isProcessingPayment: false,
  setIsProcessingPayment: noop,
  amountDue: 0,
  shouldShowTaxIdForm: false,
  isTaxIdMandatory: false,
  isBrazilianAccountAndProcessorStripeBr: false,
  isPaywallDigitalWalletEnabled: false,
  stripePaymentMethodType: "card",
  setStripePaymentMethodType: noop,
  isMemberWithSavedPaymentMethods: false,
  isMemberUsingSavedPaymentMethod: false,
  setIsMemberUsingSavedPaymentMethod: noop,
};

const PaywallCheckoutContext = createContext(initialState);

PaywallCheckoutContext.displayName = "PaywallCheckoutContext";

interface ProviderProps {
  children: ReactNode;
  currentCommunity: any;
  currentUser: any;
  isMemberAddressRequired: boolean;
  isTaxIdMandatory: boolean;
  isBrazilianAccountAndProcessorStripeBr: boolean;
  paywall: any;
  isMemberWithSavedPaymentMethods: boolean;
}

export const PaywallCheckoutContextProvider = (props: ProviderProps) => {
  const {
    children,
    isMemberAddressRequired,
    paywall,
    isTaxIdMandatory,
    isMemberWithSavedPaymentMethods,
  } = props;

  const {
    applyCouponCode,
    clearCouponCode,
    enableTaxIdCollection,
    getSelectedPrice,
    isTaxIdCollectionEnabled,
    paymentError,
    previewMutation,
    setMemberAddressAttributes,
    setPaymentError,
    setSelectedPrice,
    isCardInfoComplete,
    setIsCardInfoComplete,
    isCardInfoValid,
    isPaywallActive,
    isCardInfoRequired,
    isSetupIntentRequired,
    arePostCreateActionsRequired,
    handleError,
    isProcessingPayment,
    setIsProcessingPayment,
    isMemberUsingSavedPaymentMethod,
    setIsMemberUsingSavedPaymentMethod,
    ...restContext
  } = usePaywallCheckout({
    isMemberAddressRequired,
    isTaxIdMandatory,
    paywall,
    isMemberWithSavedPaymentMethods,
  });

  const value: PaywallCheckoutContext = {
    ...initialState,
    ...props,
    applyCouponCode,
    clearCouponCode,
    enableTaxIdCollection,
    getSelectedPrice,
    isTaxIdCollectionEnabled,
    paymentError,
    previewMutation,
    setMemberAddressAttributes,
    setPaymentError,
    setSelectedPrice,
    isCardInfoComplete,
    setIsCardInfoComplete,
    isCardInfoValid,
    isPaywallActive,
    isCardInfoRequired,
    isSetupIntentRequired,
    arePostCreateActionsRequired,
    handleError,
    isProcessingPayment,
    setIsProcessingPayment,
    isMemberUsingSavedPaymentMethod,
    setIsMemberUsingSavedPaymentMethod,
    ...restContext,
  };

  return (
    <PaywallCheckoutContext.Provider value={value}>
      {children}
    </PaywallCheckoutContext.Provider>
  );
};

export const usePaywallCheckoutContext = () =>
  useContext(PaywallCheckoutContext);
