import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { FormProvider, useForm } from "react-hook-form";
import { useContactsInWorkflowsEnabled } from "@circle-react/hooks/useContactsInWorkflowsEnabled";
import type { StringValueFilter } from "@circle-react-shared/MemberFilters/types";
import { createBaseFilter } from "@circle-react-shared/MemberFilters/types";
import { getDefaultAction } from "../../constants";
import type { Workflow } from "../../generated/WorkflowsApi.types";

const DEFAULT_AUDIENCE = {
  filter_rules: {
    filters: [
      createBaseFilter<StringValueFilter>({ key: "role", value: "Member" }),
    ],
  },
};

interface WorkflowFormProviderProps {
  workflow: Workflow;
}

export const WorkflowFormProvider = ({
  workflow,
  children,
}: PropsWithChildren<WorkflowFormProviderProps>) => {
  const hasActions = !isEmpty(workflow?.actions);
  const actions = hasActions ? workflow.actions : [getDefaultAction()];
  const isContactsInWorkflowsEnabled = useContactsInWorkflowsEnabled();

  const isBulkAction = workflow?.workflow_type === "bulk_action";
  const isScheduled = workflow?.workflow_type === "scheduled";
  const hasAudienceFilters =
    workflow?.audience?.filter_rules?.filters &&
    !isEmpty(workflow.audience.filter_rules.filters) &&
    Object.values(workflow.audience.filter_rules.filters).some(Boolean);

  const audience = useMemo(() => {
    if ((isBulkAction || isScheduled) && hasAudienceFilters) {
      return workflow.audience;
    }
    if (isContactsInWorkflowsEnabled) {
      return {
        filter_rules: {
          filters: [],
        },
      };
    }
    return DEFAULT_AUDIENCE;
  }, [
    hasAudienceFilters,
    isBulkAction,
    isContactsInWorkflowsEnabled,
    isScheduled,
    workflow.audience,
  ]);

  const schedule = isScheduled ? workflow.schedule : {};

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...workflow,
      actions,
      audience,
      schedule,
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
