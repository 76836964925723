import { usePunditUserContext } from "@circle-react/contexts";
import { useIsMarketingHubStatusEnabled } from "./useIsMarketingHubEmailBroadcastsEnabled";

export const useIsNonMemberContactsEnabled = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isMarketingHubStatusEnabled = useIsMarketingHubStatusEnabled();

  return (
    isMarketingHubStatusEnabled &&
    Boolean(currentCommunitySettings?.allow_non_member_contacts)
  );
};
